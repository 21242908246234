.captcha-container {
  padding: 50px 0;
  text-align: center;
}

.captcha-images {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  max-width: 735px;
  margin: auto;
}

.captcha-images img {
  width: 150px;
  height: 150px;
  object-fit: contain;
}

.captcha-img {
  padding: 10px;
}

.captcha-img.selected {
  border: solid 5px red;
  padding: 0px;
  margin: 5px;
}

.captcha-text {
  margin-bottom: 20px;
}

.button-container {
  display: flex;
  justify-content: center;
  margin-top: 15px;
}
