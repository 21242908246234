.securityTitle {
  text-align: center;
  margin: 1rem 0;
}

.securityButtonChoices {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin: 1rem 0;
}

.securityChoice {
  margin-right: 2rem;
  margin-left: 2rem;
  padding: 2rem;
  width: 10rem;
  height: 10rem;
  background-color: #f5f5f5;
  border: 1px solid #e0e0e0;
  border-radius: 0.375rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
  cursor: pointer;
}

.securityChoiceUnselected {
  opacity: 0.75;
}

.securityChoiceSelected {
  border: 3px solid #d22630;
}

.securityUnselectedDot {
  min-height: 0.5rem;
  min-width: 0.5rem;
  border-radius: 50%;
  background-color: white;
}

.securitySelectedDot {
  min-height: 0.5rem;
  min-width: 0.5rem;
  border-radius: 50%;
  background-color: #d22630;
}

.securityChoiceText {
  margin: 0.5rem 0 1.5rem 0;
  text-align: center;
  font-size: 1rem;
  font-weight: 500;
}

.securityConfirm {
  margin: 2rem 0;
  padding: 1rem;
  background-color: #d22630;
  color: white;
  border: none;
  border-radius: 0.375rem;
  cursor: pointer;
}

.securityConfirm:disabled {
  background-color: #f5f5f5;
  color: #e0e0e0;
  cursor: not-allowed;
}

.securityConfirm:hover:enabled {
    opacity: 0.7;
}

.securityConfirm:focus {
  outline: none;
  border-radius: 0.375rem;
}