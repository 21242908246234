.rodal-dialog {
  width: 100% !important;
  height: 470px !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

.sign-frame .rodal-dialog {
  height: 100% !important;
}

.rodal-draft {
  width: 100%;
}
