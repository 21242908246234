.input-responsive.flex > div {
  flex-grow: none;
}

.input-responsive {
  display: flex;
  justify-content: center;
  max-width: 80%;
}

.input-responsive input {
  width: 100%;
  padding: 10px 5px;
  max-width: 600px;
}
