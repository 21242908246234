.draft-filters input {
    width: 80%;
    height: 30px;
    border: solid 1px grey;
    margin: 15px 0;
}


.draft-h2 {
    margin: 10px 0 10px 0;
    padding-bottom: 2px;
    font-size: 18px;
    text-transform: uppercase;
    color: #d22630;
    border-bottom: 1px solid #d22630;
}

.button-draft {
    border: solid 1px darkgray;
    height: 30px;
    font-size: larger;
    padding: 22px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 30px;
}

.button-draft-2 {
    border: solid 1px darkgray;
    height: 30px;
    font-size: larger;
    padding: 22px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.button-draft:hover {
    opacity: 0.75;
}

@media only screen and (max-width: 900px) {
    .draft-filters input {
        height: 30px;
        border: solid 1px grey;
        margin: 15px 0;
    }


    .draft-h2 {
        padding: 5px;
        font-size: large;
        text-transform: uppercase;
        color: #d22630;
        border-bottom: 1px solid #d22630;
        text-align: center;
    }


    .button-draft:hover {
        opacity: 0.75;
    }


}